import { createRouter, createWebHistory } from 'vue-router';
import Login from '../views/LoginForm.vue';
import VideoStream from '../views/VideoStream.vue';
import VideoStream2 from '../views/VideoStream2.vue';
import ContactForm from '../views/ContactForm.vue';
import PortalFooter from '../components/PortalFooter.vue';
import PortalHeader from '../components/PortalHeader.vue';
import NavBar from '../components/NavBar.vue';
import SettingsVideo from '../views/SettingsVideo.vue';
import HomeApp from '../views/HomeApp.vue';
import store from '../store/store.js'; // Убедитесь, что store правильно экспортирован из этого файла
import MTXVue from '../views/MTX.vue';


const routes = [
  { path: '/', name: 'Login', component: Login },
  { path: '/VideoStream', name: 'VideoStream', component: VideoStream, meta: { requiresAuth: true } },
  { 
    path: '/VideoStream2/:streamName?', // Добавление '?' делает параметр необязательным
    name: 'VideoStream2', 
    component: VideoStream2, 
    meta: { requiresAuth: true },
    props: true // Это позволяет передавать параметры маршрута в компонент как props
  }, 
  { path: '/ContactForm', name: 'ContactForm', component: ContactForm, meta: { requiresAuth: true }},
  { path: '/PortalFooter', name: 'PortalFooter', component: PortalFooter, meta: { requiresAuth: false } },
  { path: '/PortalHeader', name: 'PortalHeader', component: PortalHeader, meta: { requiresAuth: false } },
  { path: '/SettingsVideo', name: 'SettingsVideo', component: SettingsVideo, meta: { requiresAuth: true} },
  { path: '/NavBar', name: 'NavBar', component: NavBar , meta: { requiresAuth: true } },
  { path: '/HomeApp', name: 'HomeApp', component: HomeApp, meta: { requiresAuth: true } },
  { path: '/MTX', name: 'MTX', component: MTXVue, meta: { requiresAuth: false } },
  { path: '/:pathMatch(.*)*', redirect: '/Login' },
];

// Define the router instance

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Define the route guards

router.beforeEach((to, from, next) => {
  const requiresAuth = to.meta.requiresAuth;
  const requiresAdmin = to.meta.requiresAdmin;

  if (requiresAuth && !store.getters['auth/isAuthenticated']) {
    // Если маршрут требует аутентификации и пользователь не аутентифицирован, перенаправляем на страницу входа
    next('/');
  } else if (requiresAdmin && !store.getters['auth/isMaster']) {
    // Если маршрут требует прав администратора и пользователь не является администратором, перенаправляем на домашнюю страницу
    next('/HomeApp');
  } else if (requiresAuth && store.getters['auth/isMaster']) {
	next('/HomeApp');
  } else if (requiresAdmin && store.getters['auth/isMaster']) {
    // Если маршрут требует прав администратора и пользователь является администратором, перенаправляем на страницу настроек видео
    next('/SettingsVideo');
  } else {
    next();
    console.log('Navigating from', from.name, 'to', to.name);
  }
});
export default router;
