<!-- src\components\NavBar.vue -->
<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <router-link to="/" class="navbar-brand">SkyVision</router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/" class="nav-link">Вход</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/HomeApp" class="nav-link">Главная</router-link>
          </li>
          <li class="nav-item" v-if="isAdmin || isMaster">
            <router-link to="/VideoStream" class="nav-link">Видео стрим</router-link>
          </li>
          <li class="nav-item" v-if="isAuthenticated">
            <router-link to="/ContactForm" class="nav-link">Обратная связь</router-link>
          </li>
          <li class="nav-item" v-if="isMaster">
            <router-link to="/SettingsVideo" class="nav-link">Настройки</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>


<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isMaster', 'isAuthenticated'])
  }
};
</script>

<!-- <style src="../views/styles/NavBar.css" lang="css"></style> -->
