<template>
  <div class="container mt-4">
    <div class="mb-3">
      <label class="form-label">Адрес сервера:</label>
      <span class="form-text">{{ serverAddress }}</span>
    </div>
    <!-- <div class="mb-3">
      <label class="form-label">Номер порта:</label>
      <span class="form-text">{{ serverPort }}</span>
    </div> -->
    <div class="mb-3">
      <label class="form-label">Имя стрима:</label>
      <span class="form-text">{{ streamName }}</span> 
    </div>
    <div class="ratio ratio-16x9">
      <iframe :src="streamUrl ? streamUrl : ''" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { watchEffect, ref } from 'vue';

export default {
  setup() {
    const store = useStore();
    const streamName = ref(store.state.stream.streamName);
    const streamUrl = ref('');

    // Генерация URL плеера
    const generateStreamUrl = () => {
      return `https://${store.state.stream.currentServerIp}/${streamName.value}`;
    };

    // Следим за изменениями streamName
    watchEffect(() => {
      streamUrl.value = generateStreamUrl();
    });

    return {
      serverAddress: store.state.stream.currentServerIp,
      serverPort: store.state.stream.streamPort,
      streamName,
      streamUrl,
    };
  },
};
</script>

<style scoped>
/* Ваши стили здесь */
</style>
