<template>
  <div>
    <h1>Доступные Трансляции</h1>
    <ul>
      <li v-for="stream in streams" :key="stream.id">
        {{ stream.name }} - {{ stream.status }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'StreamList',
  computed: {
    ...mapGetters(['allStreams']),
  },
  methods: {
    ...mapActions(['fetchStreams']),
  },
  created() {
    this.fetchStreams();
  },
};
</script>
