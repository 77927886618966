<template>
  <div class="container mt-4">
    <div class="mb-3">
      <label class="form-label">Адрес сервера:</label>
      <span class="form-text">{{ $store.state.stream.currentServerIp }}</span>
    </div>
    <!-- <div class="mb-3">
      <label class="form-label">Номер порта:</label>
      <span class="form-text">{{ $store.state.stream.streamPort }}</span>
    </div> -->
    <div class="mb-3">
      <label for="streamName" class="form-label">Имя стрима:</label>
      <input type="text" class="form-control" v-model="streamName" id="streamName" placeholder="Название стрима">
      <button @click="applyStreamName" class="btn btn-primary mt-2">Применить</button>
    </div>
    <div class="ratio ratio-16x9">
      <iframe :src="streamUrl ? streamUrl : ''" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
</template>


<script>
import { useStore } from 'vuex';
import { nextTick, ref } from 'vue';

import axios from 'axios';

export default {
  setup() {
    const store = useStore();
    const streamName = ref();
    const streamUrl = ref('');

    const generateStreamUrl = () => {
      return `https://${store.state.stream.currentServerIp}/${streamName.value}`;
    };

    const applyStreamName = () => {
      streamUrl.value = generateStreamUrl();
      nextTick(() => {
        document.getElementById('streamName').value = streamName.value;
      });
    };

    return {
      streamName,
      streamUrl,
      applyStreamName,
    };
  },
};

axios.get('http://localhost:9997/v3/config/global/get')
  .then(function (response) {
    // Обработка успешного ответа
    console.log("Глобальная конфигурация:", response.data);
  })
  .catch(function (error) {
    // Обработка ошибки
    console.error("Ошибка получения глобальной конфигурации:", error);
  });

</script>


<!-- <style src="../views/styles/VideoStream.css" lang="css"></style> -->
