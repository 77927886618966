import { createApp } from 'vue';
import App from '../src/App.vue';
import store from '../src/store/store.js';
import router from '../src/router/index.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';




// Создание экземпляра приложения Vue с использованием хранилища Vuex
const app = createApp(App);

// Обработчик ошибок
app.config.errorHandler = (err, vm, info) => {
    console.error('Error:', err);
    console.error('Component:', vm);
    console.error('Info:', info);
    // Обработка ошибок приложения

    // Перенаправление на страницу ошибки
    router.push('/error');
}

app.use(store);  // подключение хранилища Vuex
app.use(router);    // подключение маршрутизатора
app.mount('#app');  // рендер приложения в DOM-узле с id="app"
