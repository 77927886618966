<template>
  <div class="container mt-5">
    <h2 class="mb-3">Выбор модели БПЛА</h2>
    <div class="row justify-content-center">
      <div class="col-lg-3 col-md-4 col-sm-6 mb-4" v-for="(drone, index) in drones" :key="index">
        <div class="card h-100" @click="selectModel(drone.surname)">
          <img :src="drone.image" class="card-img-top" :alt="drone.alt">
          <div class="card-body">
            <h5 class="card-title text-center">{{ drone.name }}</h5>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6 mb-4">
        <div class="card h-100" @click="selectModel('stream')" v-if="isMaster">
          <img src="@/assets/no-data.png" class="card-img-top" alt="Custom">
          <div class="card-body">
            <h5 class="card-title text-center">Custom</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  computed: {
    isMaster() {
      return this.$store.getters['auth/isMaster'];
    }
  },
  methods: {
    ...mapMutations('stream', ['setStreamName']),
    selectModel(modelName) {
      this.setStreamName(modelName);
      console.log('Selected model:', modelName);
      this.$router.push({ name: 'VideoStream2' });
    },
  },
  data() {
    return {
      drones: [
        { name: 'DJI Matrice 210', surname: 'matrice', image: require('@/assets/M210.jpg'), alt: 'Matrice 210' },
        { name: 'DJI Mavic 2', surname: 'mavic', image: require('@/assets/Mavic2.jpg'), alt: 'Mavic' },
        { name: 'DJI Matrice 300RTK', surname: 'matrice2', image: require('@/assets/M300RTK.jpg'), alt: 'Matrice 300RTK' },
        // Add more drones as needed
      ]
    };
  }
};
</script>

<style scoped>
.card-img-top {
  max-height: 200px; /* Установите высоту изображений */
  width: 100%;
  height: 100%;
  object-fit: cover; /* Чтобы изображения сохраняли пропорции и заполняли контейнер */
  object-position: center; /* Чтобы изображения были в центре контейнера */
  border-radius: 5px;
  cursor: pointer;
}
</style>
