// store/stream.js
const getters = {
  getStreamName: state => state.streamName
};
export default {
  getters,
  namespaced: true,
  state: {
    streamName: '',
    streamPort: 8889,
    currentServerIp: 'crs.vogroup.agency',
    streamUrl: '', // Add streamUrl to state
  },
  mutations: {
    setStreamName(state, payload) {
      state.streamName = payload;
      console.log('Stream Name updated:', payload);
    },
    setStreamPort(state, payload) {
      state.streamPort = payload;
      console.log('Stream Port updated:', payload);
    },
    setCurrentServerIp(state, payload) {
      if (state.currentServerIp !== payload) {
        state.currentServerIp = payload;
        console.log('Current Server IP updated:', payload);
      }
    },
    setStreamUrl(state, payload) {
      state.streamUrl = payload;
      console.log('Stream URL updated:', payload);
    },
  },
  actions: {
    setStreamUrl({ commit, state }) {
      const streamUrl = `http://${state.currentServerIp}:${state.streamPort}/${state.streamName}`;
      commit('setStreamUrl', streamUrl);
      console.log('Stream URL dispatched:', streamUrl);
    },
  },
};
