<!-- src\App.vue -->
<template>
  <div>
    <PortalHeader>
      <!-- Ваш хэдер -->
    </PortalHeader>

    <main>
      <!-- Отображение содержимого страницы, зависящего от текущего маршрута -->
      <router-view />
    </main>

    <PortalFooter>
      <!-- Ваш футер -->
    </PortalFooter>
  </div>
</template>

<script>
import PortalHeader from './components/PortalHeader.vue';
import PortalFooter from './components/PortalFooter.vue';
import './views/styles/AppStyle.css';

export default {
  name: 'App',
  components: {
    PortalFooter,
    PortalHeader,
  },
};
</script>

<style src='./views/styles/AppStyle.css' lang="css"></style>
