<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">Вход</div>
          <div class="card-body">
            <form @submit.prevent="onSubmit">
              <div class="mb-3">
                <label for="username" class="form-label">Имя пользователя</label>
                <input type="text" class="form-control" id="username" v-model="username" placeholder="Введите имя пользователя">
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Пароль</label>
                <input type="password" class="form-control" id="password" v-model="password" placeholder="Введите пароль">
              </div>
              <button type="submit" class="btn btn-primary w-100">Войти</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    ...mapActions('auth', ['login']),
    async onSubmit() {
      const credentials = {
        username: this.username,
        password: this.password,
      };

      try {
        const response = await this.login(credentials);

        if (response.success) {
          console.log('Login successful');
          console.log('Access level:', response.access);
          if (response.access === 'master') {
            this.$router.push('/SettingsVideo'); // Переход на страницу "SettingsVideo"
          }
          if (response.access === 'viewer') {
            this.$router.push('/HomeApp'); // Переход на страницу "VideoStream"
          }
          if (response.access === 'admin') {
            this.$router.push('/HomeApp/@custom'); // Переход на страницу "VideoStream2"
          }
          // Выполните необходимые действия для успешной авторизации
        } else {
          console.log('Login failed');
          // Выполните необходимые действия для неуспешной авторизации
        }
      } catch (error) {
        console.error('Error during login:', error);
      }
    },
  },
};
</script>