<template>
  <footer class="mt-5 py-4 bg-light">
    <div class="container">
      <div class="contact">
        <p class="mb-0">E-mail <a :href="emailLink">vogroup.atyrau@gmail.com</a></p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      emailLink: 'mailto:vogroup.atyrau@gmail.com'
    };
  }
};
</script>