// store.js
import { createStore } from 'vuex';
import auth from './modules/auth';
import stream from './modules/stream';

const loadStateFromLocalStorage = () => {
  const state = localStorage.getItem('vuex_state');
  return state ? JSON.parse(state) : {};
};

const saveStateToLocalStorage = (state) => {
  localStorage.setItem('vuex_state', JSON.stringify(state));
};

const store = createStore({
  modules: {
    auth,
    stream,
  },
  state: {
    serverIP: '',
    streamUrl: '',
    currentServerIp: '',
  },
  mutations: {
    setServerIP(state, serverIP) {
      state.serverIP = serverIP;
      console.log('Server IP updated:', serverIP);
    },
    setStreamUrl(state, streamUrl) {
      state.streamUrl = streamUrl;
      console.log('Stream URL updated:', streamUrl);
    },
    setCurrentServerIp(state, ip) {
      state.currentServerIp = ip;
      console.log('Current Server IP updated:', ip);
      saveStateToLocalStorage(state); // Сохраняем состояние после обновления currentServerIp
    },
  },
  actions: {
    setStreamUrl({ commit }, streamUrl) {
      commit('setStreamUrl', streamUrl);
      console.log('Stream URL dispatched:', streamUrl);
    },
    setServerIP({ commit }, serverIP) {
      commit('setServerIP', serverIP);
      console.log('Server IP dispatched:', serverIP);
    },
    login({ commit }, credentials) {
      commit('auth/login', credentials);
      console.log('Login dispatched');
    },
    loadSavedState({ commit }) {
      const savedState = loadStateFromLocalStorage();
      commit('setCurrentServerIp', savedState.currentServerIp);
    },
  },
  getters: {
    // Ваши геттеры здесь
  },
});

export default store;
