<template>
  <header class="bg-light mb-4">
    <div class="container py-3">
      <h1 class="h2">Добро пожаловать в кабинет</h1>
      <NavBar />
    </div>
  </header>
</template>

<script>
import NavBar from '../components/NavBar.vue';

export default {
  components: {
    NavBar,
  },
};
</script>

