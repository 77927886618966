<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-md-6">
        <div class="mb-3">
          <label for="serverAddress" class="form-label">Адрес сервера:</label>
          <input v-model="serverAddress" id="serverAddress" class="form-control" placeholder="crs.vogroup.agency" />
        </div>
        <!-- <div class="mb-3">
          <label for="serverPort" class="form-label">Номер порта:</label>
          <input v-model.number="serverPort" id="serverPort" class="form-control" placeholder="8889" />
        </div> -->
        <button @click="applySettings" class="btn btn-primary">Применить</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      serverAddress: '',
      serverPort: null,
    };
  },
  methods: {
    applySettings() {
      // Выведем в консоль для отладки
      console.log('Applying settings:', this.serverAddress, this.serverPort);

      // Сохраняем данные в хранилище
      this.$store.commit('stream/setCurrentServerIp', this.serverAddress);
      this.$store.commit('stream/setStreamPort', this.serverPort);

      // Добавим отображение на странице с видео
      this.$router.push({ name: 'VideoStream' });
    },
  },
};
</script>

<style scoped>
/* Ваши стили здесь */
</style>
